import { Timeline } from '@telus-uds/components-web'
import { TimelineWrapper } from './Styles'

export type TimelineComponentProps = {
  timelineContent: JSX.Element[]
  accessibilityLabel?: string
  direction?: 'vertical' | 'horizontal'
}

const TimelineComponent = ({
  timelineContent,
  accessibilityLabel = 'Timeline Component',
  direction = 'vertical',
}: TimelineComponentProps) => {
  return (
    <div className="timelineContainer">
      <TimelineWrapper data-testid={`timeline-testid-${direction}`}>
        <Timeline
          accessibilityLabel={accessibilityLabel}
          variant={{ horizontal: direction === 'horizontal' ? true : false }}
        >
          {timelineContent}
        </Timeline>
      </TimelineWrapper>
    </div>
  )
}

export default TimelineComponent

import styled from 'styled-components'

export const TimelineWrapper = styled.div`
  height: auto;
  max-width: 1350px;
  position: block;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: center;
`

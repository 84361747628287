import { ITimelineFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'
import TimelineComponent from './Timeline'

const ContentfulTimeline = withContentful<ITimelineFields>(TimelineComponent, {
  timelineContent: (props) =>
    props.fields.timelineContent?.map((content) => <BlockRenderer key={content.sys.id} block={content} />),
})

export default ContentfulTimeline
